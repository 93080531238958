import React from "react";
import { useLocation } from "react-router-dom";

export default function Payment_success() {
  const location = useLocation();
  const pathname = location.pathname;
  const lastPathSegment = pathname.split("/").filter(Boolean).pop();
  return (
    <div className="payment text-center mt-5 mb-5">
      <div className="section mx-auto">
        <div className="card mx-auto">
          {lastPathSegment == "success" ? (
            <>
              <div className="rightmark">
                <i className="checkmark">✓</i>
              </div>
              <h1>Transaction Success</h1>
              <p>
                We received payment
                <br /> please login your account and check booking status!
              </p>
            </>
          ) : (
            <>
              <div className="rightmark">
                <i className="checkmark text-danger">X</i>
              </div>
              <h1>Transaction Failed</h1>
              <p>
                payment has been failed.
                <br /> please contact admin and regenerate payment link!
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
