import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getPopularService,
  getService,
} from "../redux/features/cms/serviceSlice";
import Loader from "../components/loader/Loader";
import { ASSETS } from "../config/Constants";
import parse from "html-react-parser";
import { LanguageContext } from "../components/LanguageProvider";
import { getPopularPackage } from "../redux/features/booking-data/packagesSlice";
import { Helmet } from 'react-helmet';

export default function Service_view() {
  const location = useLocation();
  const pathname = location.pathname;
  const [loading, setLoading] = useState(false);
  const lastPathSegment = pathname.split("/").filter(Boolean).pop();
  const [popularPackages, setPopularPackages] = useState([]);
  const [popularServices, setPopularServices] = useState([]);
  const [data, setData] = useState();

  const dispatch = useDispatch();

  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching data
    const fetchData = async () => {
      try {
        // Fetch service details
        await dispatch(getService(lastPathSegment)).then((res) => {
          setData(res.payload.data);
        });

        // Fetch popular packages
        const popularPackageResponse = await dispatch(getPopularPackage());
        setPopularPackages(popularPackageResponse.payload.data);

        // Fetch popular services
        const popularServiceResponse = await dispatch(getPopularService());
        setPopularServices(popularServiceResponse.payload.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, lastPathSegment]);

  if (!data || !data.service_details || !popularPackages || loading) {
    return <Loader />;
  }
  const service_data = data.service_details.content;
  const how_work = data.how_it_works;

  return (
    <>
      <Helmet>
        <title>{service_data &&service_data.meta_title}</title>
        <meta name="title" content={service_data &&service_data.meta_title} />
        <link rel="canonical" href={service_data &&service_data.canonical_url} />
        <meta name="description" content={service_data &&service_data.meta_description} />
        <meta name="keywords" content={service_data &&service_data.meta_keywords} />
      </Helmet>

      <section className="row">
        <div className="col-12 px-0 breadCrumbWrapper">
          <div className="auto-container">
            <div className="row">
              <div className="col-12 pt-4">
                <div className="row">
                  <div className="col-12 text-left">
                    <h1 className="page_title">
                      {selectedLanguage == "English"
                        ? service_data.title_en
                        : service_data.title_ar}
                    </h1>
                    <p>
                      <span>
                        <a href="/" className="text-white">
                          Home
                        </a>
                      </span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>
                        <a href="/services" className="text-white">
                          Services
                        </a>
                      </span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>
                        {selectedLanguage == "English"
                          ? service_data.title_en
                          : service_data.title_ar}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sidebar-page-container pb-0 pt-1">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-8  col-sm-12">
              <div className="content-side  background-color-white border_radius_10 pb-4 d-block mx-auto">
                <div className="service_page_design">
                  <div className="row">
                    <div className="col-12">
                      <div className="image_wrapper">
                        <picture>
                          <source
                            srcSet={
                              ASSETS +
                              "public/images/service/large/" +
                              service_data.description_webp_image
                            }
                            type="image/webp"
                          />
                          <source
                            srcSet={
                              ASSETS +
                              "public/images/service/large/" +
                              service_data.description_webp_image
                            }
                            type="image/jpg"
                          />
                          <img
                            alt="Car AC Repair"
                            title="car ac repair dubai"
                            className="img-fluid d-block mx-auto w-100 serviceImage"
                            style={{ height: "470px" }}
                            src={
                              ASSETS +
                              "public/images/service/large/" +
                              service_data.description_webp_image
                            }
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-5 mt-4">
                  <div className="col-12 col-lg-12 col-md-12 d-block mx-auto">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 content_wrapper_service_page">
                                <div className="text-justify">
                                  {parse(
                                    selectedLanguage == "English"
                                      ? service_data.short_description_en
                                      : service_data.short_description_ar
                                  )}
                                  <div className="row mb-3">
                                    <div className="col-sm-6 mt-4 text-center">
                                      <Link
                                        to="/contact-us"
                                        className="btn-style-two d-block w-100 medium-text"
                                      >
                                        <span className="btn-title">
                                          {selectedLanguage == "English"
                                            ? "Talk To Our Expert"
                                            : "تحدث مع خبراؤنا"}
                                        </span>
                                      </Link>
                                    </div>
                                    <div className="col-sm-6 mt-4 text-center">
                                      <Link
                                        to="/repair-car-quote"
                                        className="common-btn d-block w-100 btn-style-two medium-text"
                                      >
                                        <span className="btn-title">
                                          {selectedLanguage == "English"
                                            ? "Request for a Quote"
                                            : "طلب تسعيرة"}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>

                                  {parse(
                                    selectedLanguage == "English"
                                      ? service_data.description_en
                                      : service_data.description_ar
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Sidebar Side*/}
            <div className=" col-lg-4 col-md-4 col-sm-12 p-0 pl-md-3">
              <div className="sidebar_main_wrapper">
                <div className="row">
                  {/* <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-title bold-text text-dark text-uppercase d-block text-center mb-0">
                          
                          {selectedLanguage == "English"
                                            ? "Popular Packages"
                                            : "الخدمات المفضلة"}
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="packages_wrapper">
                          {popularPackages &&
                            popularPackages.map(
                              (popularPackage, popularPackageIndex) => (
                                <div
                                  className="each_package_item_sidebar"
                                  key={popularPackageIndex}
                                >
                                  <div className="image_wrapper">
                                    <Link
                                      to={
                                        "/booking-service/" + popularPackage.id
                                      }
                                      className="takeMeToBooking d-block text-center w-100"
                                    >
                                      <img
                                        className="img-fluid d-block mx-auto"
                                        src={
                                          ASSETS +
                                          "public/images/service-packages/thumb/" +
                                          popularPackage.image
                                        }
                                      />
                                    </Link>
                                  </div>
                                  <div className="text-container d-block my-auto">
                                    <h3 className="medium-text text-shadow">
                                      <Link
                                        to={
                                          "/booking-service/" +
                                          popularPackage.id
                                        }
                                        className="takeMeToBooking"
                                      >
                                        {selectedLanguage == "English"
                                          ? popularPackage.title_en
                                          : popularPackage.title_ar}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-12  mt-3">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-title bold-text text-dark text-uppercase d-block text-center mb-0">
                        
                          {selectedLanguage == "English"
                                            ? "Popular Services"
                                            : ""}
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="packages_wrapper">
                          {popularServices &&
                            popularServices.map(
                              (popularService, popularServiceIndex) =>
                                lastPathSegment !==
                                  popularService.service_title_slug && (
                                  <div
                                    className="each_service_item_sidebar"
                                    key={popularServiceIndex}
                                  >
                                    <div className="image_wrapper">
                                      <Link
                                        className="d-block w-100"
                                        to={
                                          "/services/" +
                                          popularService.service_title_slug
                                        }
                                      >
                                        <picture>
                                          <source
                                            srcSet={
                                              ASSETS +
                                              "public/images/service/thumb/" +
                                              popularService.description_webp_image
                                            }
                                            type="image/webp"
                                          />
                                          <source
                                            srcSet={
                                              ASSETS +
                                              "public/images/service/thumb/" +
                                              popularService.description_webp_image
                                            }
                                            type="image/jpg"
                                          />
                                          <img
                                            alt={
                                              popularService.icon_image_title
                                            }
                                            title={
                                              popularService.icon_image_alt
                                            }
                                            className="img-fluid d-block mx-auto w-100 serviceImage"
                                            src={
                                              ASSETS +
                                              "public/images/service/thumb/" +
                                              popularService.description_webp_image
                                            }
                                          />
                                        </picture>
                                      </Link>
                                    </div>
                                    <div className="text-container d-block my-auto">
                                      <h3 className="medium-text text-shadow">
                                        <Link
                                          to={`/services/${popularService.service_title_slug}`}
                                          aria-label={`Learn more about ${selectedLanguage === "English" ? popularService.title_en : popularService.title_ar}`}
                                        >
                                          {selectedLanguage === "English" ? popularService.title_en : popularService.title_ar}
                                        </Link>
                                      </h3>
                                      <Link
                                        to={`/services/${popularService.service_title_slug}`}
                                        className="btn btn-light py-1 px-3 small-text btn-white"
                                        aria-label={`Read more about ${selectedLanguage === "English" ? popularService.title_en : popularService.title_ar}`}
                                      >
                                        <span className="btn-title">more.</span>
                                      </Link>
                                    </div>
                                  </div>
                                )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end sidebar */}
          </div>
        </div>
      </div>
      {/* How it's work  */}
      {how_work.content.length > 0 &&
        <div className="mt-4">
          <section className="how_it_works_main_container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 max1200 min1200 d-block mx-auto">
                  <div className="sec-title text-center mb-5">
                    <h2>
                      {selectedLanguage == "English"
                        ? how_work.section_title.category_title_en
                        : how_work.section_title.category_title_ar}
                    </h2>
                    <div className="text">
                    {selectedLanguage == "English"
                        ? parse(how_work.section_title.description_en)
                        : parse(how_work.section_title.description_ar)}
                    </div>
                    <span className="devider">
                      <span></span>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="medium-text bold-text text-center">
                      {selectedLanguage == "English"
                          ? parse(how_work.section_title.highlight_en)
                          : parse(how_work.section_title.highlight_ar)}
                      </h3>
                      <div className="how_it_works_wrapper mt-4">
                        {how_work.content.map((item, index) => (
                          <div className="each_how_it_works_design" key={item.id}>
                            <div className="movile_view_wrap">
                              <div className="image_wrapper">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  src={
                                    ASSETS +
                                    "public/images/section/large/" +
                                    item.icon_file
                                  }
                                  alt="icons"
                            title="icons image"
                                />
                              </div>
                              <div className="text_container_mobile text-center">
                                <h3 className="small-text">
                                  {selectedLanguage == "English"
                                    ? item.section_title_en
                                    : item.section_title_ar}
                                </h3>
                              </div>
                            </div>
                            <div className="arrow_container text-center">
                              <span className="point">{index + 1}</span>
                            </div>
                            <div className="text_container text-center">
                              <h3 className="small-text">
                                {selectedLanguage == "English"
                                  ? item.section_title_en
                                  : item.section_title_ar}
                              </h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      }
      {/* End How it's work */}
    </>
  );
}
