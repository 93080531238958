import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../components/loader/Loader";
import { Link } from "react-router-dom";
import { LanguageContext } from "../components/LanguageProvider";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { getServicesList } from "../redux/features/cms/serviceSlice";
import { ASSETS } from "../config/Constants";

export default function Services() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  
  useEffect(() => {
    dispatch(getServicesList()).then((res) => setData(res.payload.data));
  }, []);

  const { selectedLanguage } = useContext(LanguageContext);

  if (!data) {
    return <Loader />;
  }

  const what_we_do = data.our_car_services || {};
  const seo = data.seo || {};

  return (
    <>
      <Helmet>
        <title>{seo.meta_title || ''}</title>
        <meta name="title" content={seo.meta_title || ''} />
        <link rel="canonical" href={seo.canonical_url || ''} />
        <meta name="description" content={seo.meta_description || ''} />
        <meta name="keywords" content={seo.meta_keywords || ''} />
      </Helmet>

      <section className="certificate-section pt-4 pb-5 pt-sm-5">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>
              {selectedLanguage === "English"
                ? what_we_do.section_title?.category_title_en
                : what_we_do.section_title?.category_title_ar}
            </h2>
            <div className="text">
              {selectedLanguage === "English"
                ? parse(what_we_do.section_title?.highlight_en || '')
                : parse(what_we_do.section_title?.highlight_ar || '')}
            </div>
            <span className="devider">
              <span></span>
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                {(what_we_do.content || []).map((what_content, whatIndex) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-4 col-6 mt-4"
                    key={whatIndex}
                  >
                    <Link
                      className="d-block"
                      to={"/services/" + what_content.service_title_slug}
                    >
                      <div className="each_service_package">
                        <div className="icon_container">
                          <img
                            className="img-fluid d-block mx-auto"
                            title={what_content.icon_image_title}
                            alt={what_content.icon_image_alt}
                            src={
                              ASSETS +
                              "public/images/service/large/" +
                              what_content.icon_image
                            }
                          />
                        </div>
                        <div className="text_container">
                          <h3>
                            {selectedLanguage === "English"
                              ? what_content.title_en
                              : what_content.title_ar}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
