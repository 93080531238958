import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../components/LanguageProvider";
import axios from "axios";

export default function Navbar() {
  const [menus, setMenus] = useState([]);
  let location = useLocation();
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    // Fetch menu items from the API
    const fetchMenus = async () => {
      try {
        const response = await axios.get("https://admin.autofix.bh/api/getMenus");
        if (response.data.success) {
          // Filter out items with status 0 and sort them by 'sort' field
          const filteredMenus = response.data.data
            .filter(menu => menu.status === 1)
            .sort((a, b) => a.sort - b.sort);
          setMenus(filteredMenus);
        }
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    fetchMenus();
  }, []);

  return (
    <>
      {menus.map((menu, index) => {
        const isLastItem = index === menus.length - 1;
        return (
          <li 
            className={`nav-item ${isLastItem ? "no-line" : ""}`} 
            key={menu.id}
          >
            <Link
              to={menu.slug}
              className={location.pathname === menu.slug ? "nav-link active" : "nav-link"}
              aria-label="menu.slug"
            >
              {selectedLanguage === "English" ? menu.menu_name_en : menu.menu_name_ar}
            </Link>
          </li>
        );
      })}
    </>
  );
}
