import React, { createContext, Component } from 'react';

const LanguageContext = createContext();

export class LanguageProvider extends Component {
  state = {
    selectedLanguage: 'English', // Set the default language here
  };

  changeLanguage = (newLanguage) => {
    this.setState({ selectedLanguage: newLanguage });
  };

  render() {
    return (
      <LanguageContext.Provider
        value={{
          selectedLanguage: this.state.selectedLanguage,
          changeLanguage: this.changeLanguage,
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export const LanguageConsumer = LanguageContext.Consumer;
export { LanguageContext }; // Add this line to export LanguageContext
