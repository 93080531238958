import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "getServicePackagesPageDataBySlug/packages";

// Get packages
export const getPackage = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getPackageSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getPackage.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getPackage.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getPackage.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// get popular packages
export const getPopularPackage = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/getPopularPackagesData`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getPopularPackageSlice = createSlice({
  name: "getPopularPackagesData",
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getPopularPackage.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getPopularPackage.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getPopularPackage.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});
