import React from 'react'
export default function Thankyou() {
  return (
    <div className='container mt-5 mb-5'>
        <div className='col-md-7 mx-auto'>
            <img src="/assets/front/uploads/about/thankyou.jpg" className='img-fluid' alt="thank you" />
        </div>
    </div>
  )
}
