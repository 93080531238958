import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "getCarServiceDetalsByServiceSlug";

export const getService = createAsyncThunk(
  `${namespace}/`,
  async (slug) => {
    try {
      const { data } = await axios.get(`${API_URL}/${namespace}/`+slug, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const getServiceSlice = createSlice({
    name: namespace,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [getService.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [getService.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload;
      },
      [getService.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });


  // Popular Services
  export const getPopularService = createAsyncThunk(`${namespace}`, async () => {
    try {
      const { data } = await axios.get(`${API_URL}/getPopularServicesData`, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  });
  
  export const getPopularServiceSlice = createSlice({
    name: "getPopularServicesData",
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [getPopularService.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [getPopularService.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload;
      },
      [getPopularService.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });


  
  // list Services
  export const getServicesList = createAsyncThunk('getServicePageDataBySlug/', async () => {
    try {
      const { data } = await axios.get(`${API_URL}/getServicePageDataBySlug/services`, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  });
  
  export const getServicesListSlice = createSlice({
    name: "getPopularServicesData/services",
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [getPopularService.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [getPopularService.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload;
      },
      [getPopularService.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });
