import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/loader/Loader";
import { getMake } from "../redux/features/booking-data/makeSlice";
import { getModal } from "../redux/features/booking-data/makeModelSlice";
import { submitPackageQuery } from "../redux/features/cms/packageSlice";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);

export default function Repair_car_quote() {
  const [files, setFiles] = useState([]);
  const [modal_data, setModal_data] = useState([]);
  const [make_data, setMake_data] = useState([])
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    dispatch(getMake()).then((res)=>{
      setMake_data(res.payload.data)
      setIsLoading(false)
  });
  }, []);

  const { selectedLanguage } = useContext(LanguageContext);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "car_makes_id") {
      dispatch(getModal(value)).then((res) => {
        setModal_data(res.payload.data);
      });
    }
    setFormData({ ...formData, [name]: value });
  };

  const onsubmitEnquiry = async (e) => {
    setIsLoading(true);
    const convertedForm = new FormData();
    for (var key in formData) {
      convertedForm.append(key, formData[key]);
    }
    convertedForm.append('package', false);
    files.map((image_file, index) => {
      const base64String = image_file.getFileEncodeBase64String();
      convertedForm.append("image[" + index + "]", base64String);
    });

    dispatch(submitPackageQuery(convertedForm)).then((res) => {
      if (res.payload.success) {
        toast.success(res.payload.message);
        setFormData({}); // Clear the form data
        setFiles([]);    // Clear the uploaded files
      } else if (!res.payload.success) {
        Object.values(res.payload.data).forEach((errorMessages) => {
          errorMessages.forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      } else {
        toast.error("An error occurred.");
      }
      setIsLoading(false);
    });
  };

  if (!make_data || isLoading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <Helmet>
        <title>{modal_data.seo && modal_data.seo.meta_title}</title>
        <meta name="title" content={modal_data.seo && modal_data.seo.meta_title} />
        <link rel="canonical" href={modal_data.seo &&modal_data.seo.canonical_url} />
        <meta name="description" content={modal_data.seo && modal_data.seo.meta_description} />
        <meta name="keywords" content={modal_data.seo && modal_data.seo.meta_keywords} />
      </Helmet>
      

      <form action="#" id="getAQuoteForm" data-parsley-validate>
        <div className="getAQuoteForm">
          <div className="auto-container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 d-block mx-auto mb-5 pb-5">
                <div className="card card-light border-0 box_shadow_light">
                  <div className="card-header sec-title mb-0">
                    <div className="card-title mb-0">
                      {" "}
                      <h3 className="common_heading big-text mb-0 text-center">
                        Enquiry Form
                      </h3>
                      <p className="text-center text">
                        Fill the form below to get the details for any service
                        package, exclusive offers, and much more.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="form_wrapper_booking">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-lg-6 mt-6 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>Choose Make</label>
                                <select
                                  name="car_makes_id"
                                  id="car_makes_id"
                                  onChange={(e) => onChange(e)}
                                  className="inp_field select2_initialize bg-white"
                                  required
                                >
                                  <option>Select Make</option>
                                  {make_data
                                    ? make_data.map((element, key) => {
                                        return (
                                          <option value={element.id} key={key}>
                                            {selectedLanguage=='English'?element.name_en:element.name_ar}
                                          </option>
                                        );
                                      })
                                    : null}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-6 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>Choose Model</label>
                                <select
                                  name="car_models_id"
                                  id="car_models_id"
                                  onChange={(e) => onChange(e)}
                                  className="inp_field select2_initialize bg-white"
                                  required
                                  data-parsley-required-message="Please Choose Model"
                                >
                                  <option>Select Model</option>
                                  {modal_data
                                    ? modal_data.map((element, key) => {
                                        return (
                                          <option
                                            value={element.id}
                                            key={key}
                                          >
                                            {selectedLanguage=='English'?element.name_en:element.name_ar}
                                          </option>
                                        );
                                      })
                                    : null}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 mt-4 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={
                                    formData.customer_first_name ? formData.customer_first_name : ""
                                  }
                                  className="inp_field"
                                  name="customer_first_name"
                                  id="customer_first_name"
                                  placeholder="Enter First Name"
                                  required
                                  data-parsley-required-message="Enter First Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mt-4 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={
                                    formData.customer_last_name ? formData.customer_last_name : ""
                                  }
                                  className="inp_field"
                                  name="customer_last_name"
                                  id="customer_last_name"
                                  placeholder="Enter Last Name"
                                  required
                                  data-parsley-required-message="Enter Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-4 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label htmlFor="contact_number">Contact Number</label>
                                <PhoneInput
                                  country={'bh'}
                                  value={formData.contact_number}
                                  onChange={(value) => setFormData({ ...formData, contact_number: value })} // {{ edit_1 }}
                                  inputProps={{
                                    name: 'contact_number',
                                    id: 'contact_number', // {{ edit_2 }} - Added id for label association
                                    required: true,
                                    autoFocus: true,
                                    className: 'inp_field phone-input phone-input-repair-car' // Ensure this class is styled correctly
                                  }}
                                  placeholder="Enter Contact Number"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-4 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>Plate Code</label>
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={formData.plate_code ? formData.plate_code : ""}
                                  className="inp_field"
                                  name="plate_code"
                                  id="plate_code"
                                  placeholder="Enter Plate Code"
                                  required
                                  data-parsley-required-message="Please Enter Plate Code"
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 mt-4 col-sm-6 col-12">
                              <div className="form_field_wrapper">
                                <label>Plat Number</label>
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={formData.plate_number ? formData.plate_number : ""}
                                  className="inp_field"
                                  name="plate_number"
                                  id="plate_number"
                                  placeholder="Enter Plate Number"
                                  required
                                  data-parsley-required-message="Please Enter Plate Number"
                                />
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-lg-12 mt-4 col-sm-12 col-12">
                              <div className="form_field_wrapper">
                                <label>Email Address</label>
                                <input
                                  type="email"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={formData.email ? formData.email : ""}
                                  className="inp_field"
                                  name="email"
                                  placeholder="Enter Email Address"
                                  required
                                  data-parsley-required-message="Please Enter Email Address"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12 mt-4">
                              <div className="form_field_wrapper_dsg_2">
                                <label>Description </label>
                                <textarea
                                  placeholder="Enter Description"
                                  name="description"
                                  rows="3"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={
                                    formData.description
                                      ? formData.description
                                      : ""
                                  }
                                  className="inp_field"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 form-group">
                            {isLoading ? (
                              <div className="text-center">
                                <img
                                  className="mx-auto"
                                  alt="loader"
                            title="loading..."
                                  src="/assets/front/loader/loader.gif"
                                  style={{ Height: "50px" }}
                                />
                              </div>
                            ) : (
                              <div className="row">
                                {/* <div className=" col-md-6 ">
                                  <div className="" style={{marginLeft:"-14px"}}>
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={SITE_KEY}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-6" style={{paddingLeft:"0px"}}>
                                  <div className="">
                                    <button
                                      className="mt-4 theme-btn enquiry-button theme-btn text-center submitFormButton"
                                      type="button" 
                                      onClick={() => onsubmitEnquiry()}
                                      name="submit-form"
                                    >
                                      <span className="btn-title">
                                        {selectedLanguage == "English"  ? "Request a Quote" : "طلب تسعيرة"}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="uploadFilesWrapper text-center">
                            <i className="fas fa-upload"></i>
                            <div className="dz-default dz-message">
                              <FilePond
                                files={files}
                                allowFileEncode={true}
                                allowReorder={true}
                                allowMultiple={true}
                                onupdatefiles={setFiles}
                                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
