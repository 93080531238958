import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Parser from "html-react-parser";
import { getLanguage, API_URL, API_KEY } from "../config/Constants";
import axios from "axios";
import { LanguageConsumer } from "./LanguageProvider";

class Layout extends React.Component {
  state = {
    isOpen: true,
    isMobileNavOpen: false,
    footerData: "",
    sLang: "English",
    selectedLanguage: getLanguage(),
  };

  updateState = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: API_URL + "/geFooterData",
      headers: { token: API_KEY },
    };

    axios.request(config).then((response) => {
      this.setState({ footerData: response.data.data });
    });
  };

  handleMobileNavClick = () => {
    this.setState(
      (prevState) => ({ isMobileNavOpen: !prevState.isMobileNavOpen }),
      () => { }
    );
  };

  

  render() {
    var userToken = localStorage.getItem("userToken");
    var userData = localStorage.getItem("userData");
    const { footerData, sLang } = this.state;

    const logout = () => {
      let config = {
        method: "get",
        url: API_URL + "/auth/logout",
        headers: { Authorization: `Bearer ${userToken}`, token: API_KEY },
      };

      axios.request(config).then((response) => { });
      localStorage.clear();
      toast.error("Logout success");
      window.location.reload();
    };

    const safeParser = (value) => {
      return typeof Parser(value) === 'string' ? Parser(value) : '';
    };

    const currentYear = new Date().getFullYear();
    const copyrightElement = `© ${currentYear} Auto Fix all rights reserved.`;

    return (
      <LanguageConsumer>
        {(context) => {

          if (footerData) {
            // first section
            var F_NAME =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.auto_fix.content.category_title_en
                : footerData.auto_fix.content.category_title_ar;
            var F_TITLE =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.auto_fix.content.highlight_en
                : footerData.auto_fix.content.highlight_ar;
            var F_DESCRIPTION =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.auto_fix.content.description_en
                : footerData.auto_fix.content.description_ar;
            // second section
            var S_HEADING =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.quick_links.section_title.category_title_en
                : footerData.quick_links.section_title.category_title_ar;
            var QUICK_LINKS = footerData.quick_links.content;
            // third section
            var T_HEADING =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.our_services.section_title.category_title_en
                : footerData.our_services.section_title.category_title_ar;
            var SERVICES = footerData.our_services.content;
            // fourth section
            var Fourth_Heading =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.get_in_touch.section_title.category_title_en
                : footerData.get_in_touch.section_title.category_title_ar;
            var FOOTER_ADDRESS =
              sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                ? footerData.get_in_touch.content.company_address_en
                : footerData.get_in_touch.content.company_address_ar;

            var FOOTER_EMAIL = footerData.get_in_touch.content.email;
            var S_FACEBOOK = footerData.get_in_touch.content.facebook_url;
            var S_TWITTER = footerData.get_in_touch.content.twitter_url;
            var S_WHATSAPP = footerData.site_settings.whats_app_number;
            var S_INSTAGRAM = footerData.get_in_touch.content.instagram_url;
            var HEADER_PHONE = footerData.header_section.content.highlight_en;
            var FOOTER_PHONE = footerData.header_section.content.description_en;
            var FOOTER_SECOND_PHONE = footerData.header_section.content.description_ar;



          }

          return (
            <div className="page-wrapper" dir={sLang !== "English" || context.selectedLanguage == 'Arabic' ? 'rtl' : 'ltr'}>
              <ToastContainer className="custom-toast" />
              <header className="sticky-header-top">
                <div className="header_top">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-12 max1200 min1200 d-block mx-auto">
                              <div className="d-flex justify-content-end py-1 pr-3 pr-xl-0">
                                <div className="mr-sm-4 bold-text small-text my-auto d-none d-sm-block">
                                  {/* <GoogleTranslate /> */}
                                  <div className="mr-sm-4 bold-text small-text my-auto dropdown_wrapper">
                                    {/* <a 
                                  onClick={() => {
                                      const newLanguage = context.selectedLanguage === 'English' ? 'Arabic' : 'English';
                                      context.changeLanguage(newLanguage);
                                      localStorage.setItem("lan", newLanguage);
                                      this.setState({
                                          sLang: newLanguage,
                                      });
                                  }}
                                  style={{fontWeigh:"800", color:"black"}}
                              >
                                  {context.selectedLanguage === 'English' ? 'Arabic' : 'English'}
                            </a> */}

                                  </div>
                                </div>
                                <div className="mr-sm-4 bold-text small-text my-auto d-none d-sm-block">

                                  {HEADER_PHONE ? Parser(HEADER_PHONE) : "Loading..."}

                                </div>
                                {/* <div className="mr-sm-4 bold-text small-text my-auto ">
                                <span></span>
                                {userToken ? (
                                  <div className="mr-sm-4 bold-text small-text my-auto dropdown_wrapper">
                                    <a className="border-sm-left border-sm-right px-3">
                                      {" "}
                                      Hi,{" "}
                                      {userData == "undefined"
                                        ? "loading"
                                        : userData}{" "}
                                      &nbsp;
                                      <i className="fas fa-sort-down"></i>
                                    </a>
                                    <div className="hidden-inital dropdown_container dropdown_sm">
                                      <div
                                        className="dropdown_inner"
                                        onClick={this.handleMobileNavClick}
                                      >
                                        <ul>
                                          <Link to="profile">
                                            <li>Profile</li>
                                          </Link>
                                          <Link to="booking-list">
                                            <li>Bookings</li>
                                          </Link>
                                          <Link to="change-password">
                                            <li>Change Password</li>
                                          </Link>

                                          <li
                                            className="d-block text-center bg_light"
                                            onClick={logout}
                                          >
                                            Logout
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <Link
                                    to="auth"
                                    className="border-sm-left border-sm-right px-3 login-signup"
                                  >
                                    {sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                                      ? "Login or Signup"
                                      : "تسجيل الدخول أو التسجيل"}
                                  </Link>
                                )}
                              </div> */}
                                <div className="social_icons_wrapper d-none d-sm-block">
                                  <ul className="social-icon-four">
                                    <li>
                                      <a
                                        href={S_FACEBOOK ? S_FACEBOOK : "#"}
                                        target="_blank"
                                      >
                                        <span className="fab fa-facebook-f"></span>
                                      </a>
                                    </li>
                                    {/* <li>
                                    <a
                                      href={S_TWITTER ? S_TWITTER : "#"}
                                      target="_blank"
                                    >
                                      <span className="fab fa-twitter"></span>
                                    </a>
                                  </li> */}
                                    <li>
                                      <a
                                        href={S_INSTAGRAM ? S_INSTAGRAM : "#"}
                                        target="_blank"
                                      >
                                        <span className="fab fa-instagram"></span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 border-bottom"></div>
                            <div className="col-12  max1200 min1200 d-block mx-auto d-view">
                              <nav className="navbar navbar-expand-lg navbar-light bg-custom nav_design_custom px-0 pr-3 pr-xl-0">
                                <Link className="navbar-brand logo_img" to="/">
                                  <img
                                    src="/assets/front/logo/bhawan5.png"
                                    className="img-fluid abs_logo"
                                    alt="logo"
                                    title="main-logo"
                                    loading="lazy"
                                  />
                                </Link>
                                <button
                                  className="navbar-toggler"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#navbarNav"
                                  aria-controls="navbarNav"
                                  aria-expanded={
                                    !this.state.isMobileNavOpen ? "true" : "false"
                                  }
                                  onClick={this.handleMobileNavClick}
                                  aria-label="Toggle navigation"
                                >
                                  <span className="navbar-toggler-icon"></span>
                                </button>
                                <div
                                  className={`collapse navbar-collapse px-3 px-lg-0 ${this.state.isMobileNavOpen && "hide"
                                    }`}
                                  id="navbarNav"
                                >
                                  <ul
                                    className="navbar-nav ml-auto mr-0"
                                    onClick={this.handleMobileNavClick}
                                  >
                                    <Navbar />

                                    <li className="nav-item no-line d-none">
                                      <select
                                        className="nav-link mobile-view-language"
                                        onChange={(event) => {
                                          context.changeLanguage(
                                            event.target.value
                                          );
                                          localStorage.setItem(
                                            "lan",
                                            event.target.value
                                          );
                                          this.setState({
                                            sLang: event.target.value,
                                          });
                                        }}
                                        value={context.selectedLanguage}
                                      >
                                        <option value="English">English</option>
                                        <option value="Arabic">Arabic</option>
                                      </select>
                                    </li>
                                  </ul>
                                  <div className=" d-none">
                                    <div className="social_icons_wrapper d-none d-sm-block">
                                      <ul className="social-icon-four">
                                        <li>
                                          <a href="#" target="_blank">
                                            <span className="fab fa-facebook-f"></span>
                                          </a>
                                        </li>
                                        {/* <li>
                                        <a href="#" target="_blank">
                                          <span className="fab fa-twitter"></span>
                                        </a>
                                      </li> */}
                                        <li>
                                          <a href="#" target="_blank">
                                            <span className="fab fa-instagram"></span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="mr-sm-4 bold-text small-text my-auto d-none">

                                    {FOOTER_PHONE
                                      ? Parser(FOOTER_PHONE)
                                      : "80 031 212"}
                                  </div>

                                  <li className="nav-item no-line">
                                    {/* <Link
                                    to="packages"
                                    className="nav-link btn btn-danger rounded btn-xs color-white"
                                  >
                                    {context.selectedLanguage == "English"
                                      ? "Book Now"
                                      : "احجز الان"}
                                  </Link> */}
                                  </li>
                                </div>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              {/*End Main Header */}

              {/* Dynamic content */}
              <main>{this.props.children}</main>
              {/* end Dynamic content */}

              {/* footer section */}

              {/* <a 
                href={`https://api.whatsapp.com/send?phone=${S_WHATSAPP}&text=Hi.`} 
                className="float" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Send us a message on WhatsApp"
              >
                <i className="fab fa-whatsapp my-float" aria-hidden="true"></i>
              </a> */}


              <div
                className="modal fade"
                id="addressModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              ></div>
              <footer className="main-footer footer_new ">
                <div className="auto-container">
                  <div className="widgets-outer">
                    {/*Widgets Section*/}
                    <div className="widgets-section">
                      <div className="row clearfix">
                        {/*Footer Column*/}
                        <div className="footer-column col-lg-3 col-md-12 col-sm-12">
                          <div className="footer-widget logo-widget">
                            <div className="logo logo_footer mt-0">
                              {/* <img
                              src="/assets/front/logo/footer-logo.png"
                              className="img-fluid abs_logo"
                              alt=""
                              title="Bahwanautomobiles"
                              style={{height:"70px"}}
                            /> */}
                              <div className="logo logo_footer mt-0">
                                <Link to="/">
                                  {F_NAME ?? F_NAME}
                                  <br />
                                  <span className="small-text">
                                    {Parser(F_TITLE ? F_TITLE : "Loading...")}
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="text color-white">
                              {Parser(F_DESCRIPTION ? F_DESCRIPTION : "Loading...")}
                            </div>
                          </div>
                        </div>
                        {/*Footer Column*/}
                        <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                          <div className="footer-widget links-widget">
                            <h2 className="widget-title color-white">
                              {S_HEADING ?? S_HEADING}
                            </h2>

                            <div className="widget-content white_dsg">
                              <ul className="list clearfix">
                                {QUICK_LINKS &&
                                  Array.isArray(QUICK_LINKS) &&
                                  QUICK_LINKS.map((link, linkIndex) => (
                                    <li className="d-block" key={linkIndex}>
                                      <Link to={link.link}>
                                        {sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                                          ? link.menu_name_en
                                          : link.menu_name_ar}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                          <div className="footer-widget links-widget">
                            <h2 className="widget-title color-white">
                              {T_HEADING ?? T_HEADING}
                            </h2>

                            <div className="widget-content white_dsg">
                              <ul className="list clearfix">
                                {SERVICES &&
                                  Array.isArray(SERVICES) &&
                                  SERVICES.map((service, serviceIndex) => (
                                    <li key={serviceIndex}>
                                      <Link
                                        to={
                                          "/services/" +
                                          service.service_title_slug
                                        }
                                      >
                                        {sLang == "English" && context.selectedLanguage == "English" && context.selectedLanguage == "English"
                                          ? service.title_en
                                          : service.title_ar}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/*Footer Column*/}
                        <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                          <div className="footer-widget contact-widget white_dsg">
                            <h2 className="widget-title color-white">
                              {Fourth_Heading ?? Fourth_Heading}
                            </h2>
                            <div className="widget-content">
                              <ul className="contact-info-list">
                                <li className="color-white">
                                  <span className="fa fa-map-marker"></span>
                                  {Parser(FOOTER_ADDRESS ? FOOTER_ADDRESS : "Loading...")}
                                </li>
                                <li>
                                  <span className="fa fa-envelope-open"></span>
                                  <a
                                    href={
                                      FOOTER_EMAIL ? "to:" + FOOTER_EMAIL : "#"
                                    }
                                  >
                                    {FOOTER_EMAIL ? FOOTER_EMAIL : "#"}
                                  </a>
                                </li>
                                <li>
                                  <span className="fa fa-phone"></span>
                                  <a
                                    href={FOOTER_PHONE ? `tel:${safeParser(FOOTER_PHONE)}` : "#"}
                                  >
                                    {FOOTER_PHONE ? Parser(FOOTER_PHONE) : "#"}
                                  </a>
                                </li>

                                {FOOTER_SECOND_PHONE &&
                                  <li>
                                    <span className="fa fa-phone"></span>
                                    <a
                                      href={FOOTER_SECOND_PHONE ? `tel:${safeParser(FOOTER_SECOND_PHONE)}` : "#"}
                                    >
                                      {FOOTER_SECOND_PHONE ? Parser(FOOTER_SECOND_PHONE) : "#"}
                                    </a>
                                  </li>
                                }
                              </ul>
                            </div>

                            <ul className="social-icon-two">
                              <li>
                                <a
                                  href={S_FACEBOOK ? S_FACEBOOK : "#"}
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <span className="fab fa-facebook-f"></span>
                                </a>
                              </li>
                              {/* <li>
                              <a
                                href={
                                  S_WHATSAPP
                                    ? "https://api.whatsapp.com/send?phone=" +
                                      S_WHATSAPP
                                    : "#"
                                }
                                aria-label="Chat with us on WhatsApp"
                                target="_blank"
                              >
                                <span className="fab fa-whatsapp"></span>
                              </a>
                            </li> */}
                              <li>
                                <a
                                  href={S_INSTAGRAM ? S_INSTAGRAM : "#"}
                                  target="_blank"
                                  aria-label="instagram"
                                >
                                  <span className="fab fa-instagram"></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mx-auto">
                          <p className="text-center text-white">{copyrightElement}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
              {/*End Main Footer*/}

              {/* Scroll To Top */}
              <div className="scroll-to-top scroll-to-target" data-target="html">
                <span className="fa fa-angle-up"></span>
              </div>
            </div>
          )
        }}
      </LanguageConsumer>
    );
  }
}

export default Layout;
