import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "getContactUsPageDataBySlug/contact-us";


// Get banners
export const getContact = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getContactSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getContact.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getContact.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getContact.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

