import React, { useEffect, useState, useContext } from "react";
import Loader from "../components/loader/Loader";
import { API_URL, axiosConfig } from "../config/Constants";
import axios from "axios";
import parse from "html-react-parser";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

export default function Terms_conditions() {
  const [content, setContent] = useState();
  const [termsConditions, setTermsConditions] = useState();
  useEffect(() => {
    axios.get(API_URL + "/getTermsAndConditionsData/terms-and-conditions", axiosConfig).then((response) => {
      setContent(response.data.data.terms_and_conditions.content);
      setTermsConditions(response.data.data.seo);
    });
  }, []);
  const { selectedLanguage } = useContext(LanguageContext);

  if (!content) {
    return <Loader />;
  }

  return (
    <>
       <Helmet>
        <title>{termsConditions &&termsConditions.meta_title}</title>
        <meta name="title" content={termsConditions &&termsConditions.meta_title} />
        <link rel="canonical" href={termsConditions &&termsConditions.canonical_url} />
        <meta name="description" content={termsConditions &&termsConditions.meta_description} />
        <meta name="keywords" content={termsConditions &&termsConditions.meta_keywords} />
      </Helmet>
    
      {/* Pricing Section two */}
      <section className="pricing-section-two">
        <div className="auto-container">
          <div className="sec-title text-center mb-0">
            <h2>
              {selectedLanguage == "English"
                ? content.category_title_en
                : content.category_title_ar}
            </h2>
            <span className="devider">
              <span></span>
            </span>
          </div>
        </div>
      </section>
      <section>
        <div className="slider_section_description mb-5">
          <div className="auto-container">
            <div className="content_container_about_us">
              <div className="main_content">
                {selectedLanguage === "English"
                  ? parse(content.description_en)
                  : content.description_ar
                  ? parse(content.description_ar)
                  : "No Arabic description available"}{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Certificate Section */}
    </>
  );
}
