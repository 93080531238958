import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAbout } from "../redux/features/cms/aboutSlice";
import Loader from "../components/loader/Loader";
import parse from "html-react-parser";
import { ASSETS } from "../config/Constants";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

export default function AboutUs() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  const { loading, data } = useSelector((state) => state.about);

  if (
    loading === "PENDING" ||
    !data ||
    !data.data ||
    !data.data.about_auto_fix
  ) {
    return <Loader />;
  }

  const getTitle = (category_title_en, category_title_ar) => {
    return selectedLanguage === "English"
      ? category_title_en
      : category_title_ar;
  };

  const getDescription = (description_en, description_ar) => {
    return parse(
      selectedLanguage === "English"
        ? description_en || ""
        : description_ar || ""
    );
  };

  const getTitleAndDescription = (section) => {
    return (
      <div className="main_content">
        <h3 className="big-text mb-4">
          {getTitle(section.category_title_en, section.category_title_ar)}
        </h3>
            {selectedLanguage === "English"
              ? parse(section.description_en)
              : parse(section.description_ar)}
        <p></p>
      </div>
    );
  };

  return (
    <>
        <Helmet>
        <title>{data.data.seo &&data.data.seo.meta_title}</title>
        <meta name="title" content={data.data.seo &&data.data.seo.meta_title}/>
        <link rel="canonical" href={data.data.seo &&data.data.seo.canonical_url} />
        <meta name="description" content={data.data.seo && data.data.seo.meta_description} />
        <meta name="keywords" content={data.data.seo && data.data.seo.meta_keywords} />
      </Helmet>

      <section className="pricing-section-two">
        <div className="auto-container">
          <div className="sec-title text-center mb-0">
            <h2>
              {getTitle(
                data.data.about_auto_fix.section_title.category_title_en,
                data.data.about_auto_fix.section_title.category_title_ar
              )}
            </h2>
            <span className="devider">
              <span></span>
            </span>
          </div>
        </div>
      </section>

      <section>
        <div className="auto-container">
          <div className="sec-title text-left">
            {getDescription(
              data.data.about_auto_fix.section_title.description_en,
              data.data.about_auto_fix.section_title.description_ar
            )}
          </div>
        </div>
      </section>

      <section className="about_us">
        <div className="slider_section_description mb-5">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div className="content_container_about_us">
                      <div className="row">
                        <div className="col-md-6  order-2 d-block my-auto">
                          <div className="row">
                            <div className="col">
                              <div className="image_wrapper">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  loading="lazy" 
                                  alt="about us"
                                  title="about us main"
                                  src={
                                    ASSETS +
                                    "public/images/general-section/large/" +
                                    data.data.purpose.section_title.icon_file
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 d-block my-auto">
                            {getTitleAndDescription(
                              data.data.purpose.section_title
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className="content_container_about_us">
                      <div className="row">
                        <div className="col-md-6   d-block my-auto">
                          <div className="row">
                            <div className="col">
                              <div className="image_wrapper">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  loading="lazy" 
                                  alt="about us second image"
                                  title="about us"
                                  src={
                                    ASSETS +
                                    "public/images/general-section/large/" +
                                    data.data.our_vision_values.section_title
                                      .icon_file
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 d-block my-auto mission-div">
                            {getTitleAndDescription(
                              data.data.our_vision_values.section_title
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
