import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, API_KEY, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "auth/saveTheBookingDetails";
const namespaceone = "auth/getUserBookings";
const namespacetwo = "auth/getUserBookingDetails";

export const postBooking = createAsyncThunk(
  `${namespace}`,
  async (formData) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const { data } = await axios.post(`${API_URL}/${namespace}`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`, "token":API_KEY
        },
      });
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const postBookingSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [postBooking.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [postBooking.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [postBooking.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// get bookings list
export const getBooking = createAsyncThunk(`${namespaceone}`, async () => {
  try {
    const userToken = localStorage.getItem("userToken");
    const { data } = await axios.get(`${API_URL}/${namespaceone}`, {
      headers: {
        Authorization: `Bearer ${userToken}`, "token":API_KEY
      },
    });
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getBookingSlice = createSlice({
  name: namespaceone,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getBooking.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getBooking.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getBooking.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// end booking list end

// get single booking details
export const getSingleBooking = createAsyncThunk(
  `${namespacetwo}`,
  async (id) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const { data } = await axios.get(`${API_URL}/${namespacetwo}/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`, "token":API_KEY
        },
      });
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const getSingleBookingSlice = createSlice({
  name: namespacetwo,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getSingleBooking.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getSingleBooking.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getSingleBooking.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});
// end single booking details



// Payment getway api
export const postPayment = createAsyncThunk(
  `${namespace}`,
  async (formData) => {
    try {
      const { data } = await axios.post(`${formData.ccavenu_url}`, formData, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const postPaymentSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [postPayment.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [postPayment.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [postPayment.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});



