import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader/Loader";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ASSETS } from "../config/Constants";
import { Link } from "react-router-dom";
import { getHomePage } from "../redux/features/cms/homeSlice";
import parse from "html-react-parser";
import { LanguageContext } from "../components/LanguageProvider";
import { toast } from "react-toastify";
import { submitSubscribe } from "../redux/features/customer-requests/subscribeSlice";
import { Helmet } from 'react-helmet';
import Parser from "html-react-parser";

export default function Homepage() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getHomePage());
  }, [selectedLanguage]);

  const { loading, data } = useSelector((state) => state.homepage);

  if (!data || !data.data || !data.data.home_banners) {
    return <Loader />;
  }

  const banners = data.data.home_banners;
  const seo = data.data.seo;
  const packages =
    data.data.service_packages.service_packages_with_specifications;
  const how_work = data.data.how_it_works;
  const header_points = data.data.header_points;
  const care_care = data.data.car_brands_we_work_with;
  const why_choose_us = data.data.why_choose_us;
  const why_choose_us_images = data.data.why_choose_us_images;
  const our_car_service_partners = data.data.our_car_service_partners;
  const join_our_newsletter = data.data.join_our_newsletter;
  const faqs_image = data.data.faqs_image;
  const faqs = data.data.faqs;
  const customer_reviews = data.data.customer_reviews;
  const what_we_do = data.data.what_we_do;

  if (loading === "PENDING") {
    return <Loader />;
  }
  const safeParser = (value) => {
    return typeof Parser(value) === 'string' ? Parser(value) : '';
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onsubmitSubscribe = async (e) => {
    if (!formData.email) {
      toast.error("email should required");
      return false;
    }
    setIsLoading(true);
    dispatch(submitSubscribe(formData)).then((res) => {
      if (res.payload.success) {
        toast.success(res.payload.message);
      }
      setIsLoading(false);
      setFormData({ email: "" });
    });
  };

  return (
    <>
      <Helmet>
        <title>{seo &&seo.meta_title}</title>
        <meta name="title" content={seo &&seo.meta_title}/>
        <link rel="canonical" href={seo &&seo.canonical_url} />
        <meta name="description" content={seo &&seo.meta_description} />
        <meta name="keywords" content={seo &&seo.meta_keywords} />
      </Helmet>

      {/* Banner Section */}
      <OwlCarousel
  className="owl-theme"
  items={1}
  slideBy={1}
  loop={true}
  autoplay={true}
  dots={false}
  rtlClass="owl-rtl"
  rtl={true}
  nav={true}
  navText={["<", ">"]}
  navContainer=".custom-nav"
>
  {banners
    ? banners.map((banner, key) => {
        return (
          selectedLanguage === banner.banner_type && (
            <div className="item one" key={key}>
              <div className="af_slider">
                <a
                  href={banner.url_link ? banner.url_link : '#'}
                  package-id="23"
                  package-type="SP"
                  className="takeMeToBooking"
                >
                  <picture>
                    <source
                      media="(min-width:991px)"
                      srcSet={
                        ASSETS +
                        "public/images/home-banner/large/" +
                        banner.image_big_screen.replace(".jpg", ".webp")
                      }
                      type="image/webp"
                    />
                    <source
                      media="(min-width:575px)"
                      srcSet={
                        ASSETS +
                        "public/images/home-banner/large/" +
                        banner.image_big_screen.replace(".jpg", ".webp")
                      }
                      type="image/webp"
                    />
                    <img
                      src={
                        ASSETS +
                        "public/images/home-banner/large/" +
                        banner.image_big_screen
                      }
                      alt="Values"
                      width="1920"
                      height="500"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </picture>
                </a>
              </div>
            </div>
          )
        );
      })
    : null}
</OwlCarousel>

      {/*End Banner Section */}

      {/* Banner Points */}
      <div className="packages_wrapper">
        <div className="auto-container">
          <div className="row">
            <div className="col-12 mt-3 mb-3">
              <div className="row no-gutters justify-content-center">
                {header_points.map((head_point, headIndex) => (
                  <div
                    className="col-lg col-md-4 mt-2 col-sm-6 col-6"
                    key={headIndex}
                  >
                    <div className="each_af_features bg_light border_radius_10 mx-1">
                      <div className="icon_container ">
                        <i className="fas normal-text fa-check"></i>
                      </div>
                      <div className="text_container">
                        <h3>
                          {selectedLanguage == "English"
                            ? head_point.section_title_en
                            : head_point.section_title_ar}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* Packages*/}
      {/* <div className="mt-sm-5">
        <section>
          <div className="packages_wrapper">
            <div className="auto-container">
              <div className="sec-title text-center mt-3 mb-0">
                <h2>
                  {selectedLanguage == "English"
                    ? "Service Packages"
                    : "باقات الخدمة"}
                </h2>
                <span className="devider">
                  <span></span>
                </span>
              </div>

              <div className="text-center m-1"></div>
              <div className="row rect-owel package-one">
                {packages ? (
                  <OwlCarousel
                    className="owl-theme"
                    {...options}
                    loop
                    smartSpeed={700}
                    margin={20}
                  >
                    {packages.map((singlePackage, singlekey) => (
                      <div className="item" key={singlekey}>
                        <div className="each_package_container">
                          <div
                            className="content_package"
                            id={"package_" + singlekey}
                          >
                            <h3 className="bold-text">
                              {selectedLanguage == "English"
                                ? singlePackage.title_en
                                : singlePackage.title_ar}
                            </h3>
                            <span className="price_wrapper d-block mt-4 mb-4 text-center">
                              <span className="medium-text">
                                {selectedLanguage == "English"
                                  ? singlePackage.currency_type_en
                                  : singlePackage.currency_type_ar}
                                &nbsp;
                              </span>
                              <span className="biggest-text bold-text primary-color">
                                {singlePackage.current_price}
                              </span>
                            </span>
                            <div className="points_container mt-1">
                              <ul>
                                {singlePackage.select_specifications_of_packages.map(
                                  (listItems, listIndex) => (
                                    <li
                                      className="included abs_content_wrapper"
                                      key={listIndex}
                                    >
                                      <span>
                                        {selectedLanguage == "English"
                                          ? listItems.spcification_name_en
                                          : listItems.spcification_name_ar}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                            <p className="btn_container pt-4">
                              <Link
                                to={"/booking-service/" + singlePackage.id}
                                className="theme-btn btn-style-two takeMeToBooking d-block w-100 text-center"
                              >
                                {selectedLanguage == "English"
                                  ? "Book Now"
                                  : "احجز الان"}{" "}
                                &nbsp;
                                <i className="fas fa-arrow-right"></i>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div> */}
      {/* End Packages */}

      {/* How it's work  */}

      {how_work.content.length > 0 &&
        <div className="mt-4">
          <section className="how_it_works_main_container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 max1200 min1200 d-block mx-auto">
                  <div className="sec-title text-center mb-5">
                    <h2>
                      {selectedLanguage == "English"
                        ? how_work.section_title.category_title_en
                        : how_work.section_title.category_title_ar}
                    </h2>
                    <div className="text">
                      {selectedLanguage == "English"
                        ? parse(how_work.section_title.description_en)
                        : parse(how_work.section_title.description_ar)}
                    </div>
                    <span className="devider">
                      <span></span>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="medium-text bold-text text-center">
                        {selectedLanguage == "English"
                          ? parse(how_work.section_title.highlight_en)
                          : parse(how_work.section_title.highlight_ar)}
                      </h3>
                      <div className="how_it_works_wrapper mt-4">
                        {how_work.content.map((item, index) => (
                          <div className="each_how_it_works_design" key={item.id}>
                            <div className="movile_view_wrap">
                              <div className="image_wrapper">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  loading="lazy"
                                  src={
                                    ASSETS +
                                    "public/images/section/large/" +
                                    item.icon_file
                                  }
                                  height="80"
                                  width="80"
                                />
                              </div>
                              <div className="text_container_mobile text-center">
                                <h3 className="small-text">
                                  {selectedLanguage == "English"
                                    ? item.section_title_en
                                    : item.section_title_ar}
                                </h3>
                              </div>
                            </div>
                            <div className="arrow_container text-center">
                              <span className="point">{index + 1}</span>
                            </div>
                            <div className="text_container text-center">
                              <h3 className="small-text">
                                {selectedLanguage == "English"
                                  ? item.section_title_en
                                  : item.section_title_ar}
                              </h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      }

      {/* End How it's work */}

      {/* Car Brands We Work With */}
      <section className="certificate-section pt-4 pb-0 pt-sm-5">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>
              {care_care && selectedLanguage == "English"
                ? care_care.section_title.category_title_en
                : care_care.section_title.category_title_ar}
            </h2>
            <div className="text">
              {care_care && selectedLanguage == "English"
                ? parse(care_care.section_title.highlight_en)
                : parse(care_care.section_title.highlight_ar)}
            </div>
            <span className="devider">
              <span></span>
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="et-icon-box-container column-8 et-make-container"
                data-gap="8"
              >
                {care_care.content
                  ? care_care.content.map((brand, key) => {
                      return (
                        <div className="et-make et-icon-box" key={key}>
                          <a className="d-block" href="#">
                            <div className="each_brand_category">
                              <div className="icon_container">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  loading="lazy"
                                  title={brand.logo_title}
                                  alt={ brand.logo_alt}
                                  srcSet={
                                    ASSETS +
                                    "public/images/car-brand-logos/thumb/" +
                                    brand.logo
                                  }
                                />
                              </div>
                              <div className="text_container">
                                <h3>
                                  {selectedLanguage == "English"
                                    ? brand.name_en
                                    : brand.name_ar}
                                </h3>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Car Brands We Work With */}

      {/* Book Now button */}
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="call-btn-wrapper">
              <p className="btn_container mb-5 mt-3 text-center">
                {/* <Link
                  to="packages"
                  className="theme-btn btn-style-two d-inline-block text-center text-uppercase"
                >
                  <span className="btn-title">
                    <i className="far fa-calendar-check mr-2"></i>{" "}
                    {selectedLanguage == "English" ? "Book Now" : "احجز الان"}
                  </span>
                </Link> */}
                <a
                  href="tel:80 031 212"
                  data-number="80 031 212"
                  className="d-none theme-btn btn-style-one blue takeMeToBooking d-inline-blocktext-center text-uppercase callNowBtn"
                >
                  <span className="btn-title">
                    <i className="fas fa-phone rotated mr-2"></i> Call Us Today
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* WHY CHOOSE US */}
      <section className="about-section-two why-choose pt-4 pb-0 pt-sm-5">
        <div className="auto-container">
          <div className="row clearfix mb-4">
            <div className="content-column mb-0 col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="no_after sec-title text-center text-lg-left">
                  <h2>
                    {selectedLanguage === "English"
                      ? why_choose_us.section_title.category_title_en
                      : why_choose_us.section_title.category_title_ar}
                  </h2>
                  <span className="devider">
                    <span></span>
                  </span>
                  <div className="text"></div>
                </div>

                {why_choose_us.content.map((choose, chooseIndex) => (
                  <div className="feature-block-three" key={chooseIndex}>
                    <div className="inner-box">
                      <div className="icon-box">
                        <span className={choose.icon}></span>
                      </div>
                      <h3>
                        <a href="#">
                          {selectedLanguage === "English"
                            ? choose.section_title_en
                            : choose.section_title_ar}
                        </a>
                      </h3>
                      <div className="text">
                        {selectedLanguage === "English"
                          ? parse(choose.description_en)
                          : choose.description_ar
                          ? parse(choose.description_ar)
                          : "No Arabic description available"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="image-column mb-0 col-lg-6 col-md-12 col-sm-12 d-none d-lg-block my-auto">
              <div className="inner-column max_width_image_wrapper">
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  slideBy={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  nav={true}
                  rtl={true}
                  navText={["<", ">"]}
                  navContainer=".custom-nav"
                >
                  {why_choose_us_images.content.map(
                    (why_image, why_imageIndex) => (
                      <div className="item one" key={why_imageIndex}>
                        <div className="af_slider">
                          <a
                            href="#"
                            package-id="23"
                            package-type="SP"
                            className="takeMeToBooking"
                          >
                            <picture>
                              <source
                                media="(min-width:991px)"
                                srcSet={
                                  ASSETS +
                                  "public/images/section/large/" +
                                  why_image.icon_file
                                }
                              />
                              <source
                                media="(min-width:575px)"
                                srcSet={
                                  ASSETS +
                                  "public/images/section/large/" +
                                  why_image.icon_file
                                }
                              />
                              <img
                                src={
                                  ASSETS +
                                  "public/images/section/large/" +
                                  why_image.icon_file
                                }
                                alt="Values"
                                loading="lazy"
                                className="img-fluid"
                              />
                            </picture>
                          </a>
                        </div>
                      </div>
                    )
                  )}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End WHY CHOOSE US */}

      {/* Our partners */}

      {our_car_service_partners.content.length > 0 &&
      <section className="py-5 bg_light">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>
              {selectedLanguage == "English"
                ? our_car_service_partners.section_title.category_title_en
                : our_car_service_partners.section_title.category_title_ar}
            </h2>
            <div className="text">
              {selectedLanguage == "English"
                ? parse(our_car_service_partners.section_title.highlight_en)
                : parse(our_car_service_partners.section_title.highlight_ar)}
            </div>
            <span className="devider">
              <span></span>
            </span>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="our_partners_wrapper">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay
                  autoplayTimeout={2000}
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 3,
                    },
                    1000: {
                      items: 5,
                    },
                  }}
                  dots={false}
                  rtlClass="owl-rtl"
                  rtl={true}
                >
                  {our_car_service_partners.content.map((partner, index) => (
                    <div className="item" key={index}>
                      <div className="each_item_wrapper">
                        <img
                          className="img-fluid"
                          loading="lazy"
                          alt={`Partner ${index + 1}`}
                          src={
                            ASSETS +
                            "public/images/associates/large/" +
                            partner.associate_image
                          }
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      }

      {/* subscribe */}
      <section className="call-to-action-two normal-text-small-screen py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 min1200 max1200 d-block mx-auto">
              <div className="row">
                <div className="col-md-6 d-block mx-auto text-left d-block my-auto">
                  <div className="content">
                    <h2 className="color-white mb-0">
                      {selectedLanguage == "English"
                        ? join_our_newsletter.section_title.category_title_en
                        : join_our_newsletter.section_title.category_title_ar}
                    </h2>
                    <div className="color-white small-text">
                      {selectedLanguage == "English"
                        ? parse(join_our_newsletter.section_title.highlight_en)
                        : parse(join_our_newsletter.section_title.highlight_ar)}
                    </div>

                    <div id="newsletterSubscriptionForm">
                      <div className="input-group mt-4">
                        <input
                          className="form-control form-control-sm updateMe inputFieldSmall"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={onChange}
                          style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            paddingLeft: "25px",
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="theme-btn btn-style-one submitNewsletterSubscription"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                              borderTopRightRadius: "25px",
                              borderBottomRightRadius: "25px",
                            }}
                            onClick={(e) => onsubmitSubscribe()}
                          >
                            <span className="btn-title">
                              {isLoading
                                ? "Submitting"
                                : selectedLanguage == "English"
                                ? "SUBMIT"
                                : "تقديم"}
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className=" mt-3" id="newsletterResponse"></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="call_to_action_image_wrapper">
                    <img
                      className="img-fluid"
                      loading="lazy"
                      alt="newsletter"
                      title="newsletter-main"
                      src={
                        ASSETS +
                        "public/images/general-section/large/" +
                        join_our_newsletter.section_title.icon_file
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end subscribe */}

      {/* FAQ's Section */}
      <section className="faq-section pt-4 pt-sm-5 pb-0">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>
              {selectedLanguage == "English"
                ? faqs.section_title.category_title_en
                : faqs.section_title.category_title_ar}
            </h2>

            <div className="text">
              {selectedLanguage == "English"
                ? parse(faqs.section_title.highlight_en)
                : parse(faqs.section_title.highlight_ar)}
            </div>
            <span className="devider">
              <span></span>
            </span>
          </div>
          <div className="row clearfix">
            <div className="content-column col-xl-7 col-lg-6 col-md-12 col-sm-12 order-2">
              <div
                id="accordionExample"
                className="accordion shadow accordion-box"
              >
                {faqs.content.map((element, index) => (
                  <div className="card" key={index}>
                    <div
                      id={"headingOne" + index}
                      className="card-header shadow-sm border-0 acc-btn"
                    >
                      <h6 className="mb-0 font-weight-bold">
                        <a
                          href="#"
                          data-toggle="collapse"
                          data-target={"#collapseOne" + index}
                          aria-controls={"collapseOne" + index}
                          className={
                            index == 0
                              ? "d-block position-relative text-white collapsible-link py-2"
                              : "d-block position-relative text-white collapsed collapsible-link py-2"
                          }
                        >
                          {selectedLanguage == "English"
                            ? parse(element.title_en)
                            : parse(element.title_ar)}
                        </a>
                      </h6>
                    </div>
                    <div
                      id={"collapseOne" + index}
                      aria-labelledby={"headingOne" + index}
                      data-parent="#accordionExample"
                      className={index == 0 ? "collapse" : "collapse"}
                    >
                      <div className="card-body">
                        <div className="font-weight-light m-0 content">
                          {selectedLanguage == "English"
                            ? parse(element.description_en)
                            : parse(element.description_ar)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Image Column */}
            <div className="image-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <span className="float-text">AUTO FIX</span>
                <figure className="image">
                  <img
                    src={ASSETS +
                      "public/images/general-section/large/" +faqs_image.icon_file}
                    loading="lazy"
                    alt="AUTO FIX"
                  />
                </figure>
                <div className="caption-box mt-5">
                  <h4>
                    <span className="small-text">
                      
                      {faqs_image.highlight_en ? Parser(faqs_image.highlight_en) : ""}
                    </span>
                   <a href={faqs_image.description_en ? `tel:${safeParser(faqs_image.description_en)}` : "#"}  className="bold-text">
                      {faqs_image.description_en ? Parser(faqs_image.description_en) : "#"}
                  </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ's Section */}

      {/* Testimonials */}
      {customer_reviews.content.length>0 &&
      <section className="py-5 bg_light mt-0 mt-md-5">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>
              {selectedLanguage == "English"
                ? customer_reviews.section_title.category_title_en
                : customer_reviews.section_title.category_title_ar}
            </h2>
            <span className="devider">
              <span></span>
            </span>
            <div className="text"></div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="testimonials_container">
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  slideBy={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  rtlClass="owl-rtl"
        rtl={true}
                  {...options}
                >
                  {customer_reviews.content.map((review, reviewIndex) => (
                    <div
                      className={"item" + " " + { reviewIndex }}
                      key={reviewIndex}
                    >
                      <div className="each_testimonial text-center">
                        <div className="testimonial_head">
                          <div className="testimonial_header">
                            <span className="elementskit-watermark-icon ekit_watermark_icon_custom_position">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="55.331"
                                height="39.522"
                                viewBox="0 0 55.331 39.522"
                              >
                                <path
                                  id="quote_icon"
                                  d="M11.452,50.022H23.309l7.9-15.809V10.5H7.5V34.213H19.357Zm31.618,0H54.926l7.9-15.809V10.5H39.118V34.213H50.974Z"
                                  transform="translate(62.831 50.022) rotate(180)"
                                  fill="#04367d"
                                  opacity="0.2"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="testimonial_data">
                              {selectedLanguage == "English"
                                ? review.message_en
                                : review.message_ar}
                          </div>
                        </div>
                        <div className="testimonial_footer">
                          <div className="image_wrapper">
                            <img
                              className="img-fluid"
                              loading="lazy"
                              height="70"
                              width="100"
                              src={
                                ASSETS +
                                "public/images/testimonial/large/" +
                                review.person_image
                              }
                            />
                          </div>
                          <div className="client_data">
                            <h3>
                              {selectedLanguage == "English"
                                ? review.person_name_en
                                : review.person_name_ar}
                            </h3>
                            <span>
                              {[...Array(review.rating)].map((_, index) => (
                                <i key={index} className="fas fa-star"></i>
                              ))}
                              {[...Array(5 - review.rating)].map((_, index) => (
                                <i
                                  key={index + review.rating}
                                  className="far fa-star"
                                ></i>
                              ))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      {/* End Testimonial */}

      {/* Services */}
      <section className="certificate-section pt-4 pb-0 pt-sm-5">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>
              {selectedLanguage == "English"
                ? parse(what_we_do.section_title.category_title_en)
                : parse(what_we_do.section_title.category_title_ar)}
            </h2>

            <div className="text">
              {selectedLanguage == "English"
                ? parse(what_we_do.section_title.highlight_en)
                : parse(what_we_do.section_title.highlight_ar)}
            </div>
            <span className="devider">
              <span></span>
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                {what_we_do.content.map((what_content, whatIndex) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-4 col-6 mt-4"
                    key={whatIndex}
                  >
                    <Link
                      className="d-block"
                      to={"/services/" + what_content.service_title_slug}
                    >
                      <div className="each_service_package">
                        <div className="icon_container">
                          <img
                            className="img-fluid d-block mx-auto"
                            loading="lazy"
                            title={what_content.icon_image_title}
                            alt={what_content.icon_image_alt}
                            src={
                              ASSETS +
                              "public/images/service/large/" +
                              what_content.icon_image
                            }
                          />
                        </div>
                        <div className="text_container">
                          <h3>
                            {selectedLanguage == "English"
                              ? what_content.title_en
                              : what_content.title_ar}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Services */}

      {/* Book Now button */}
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="call-btn-wrapper">
              <p className="btn_container mb-5 mt-3 text-center">
                {/* <Link
                  to="packages"
                  className="theme-btn btn-style-two d-inline-block text-center text-uppercase"
                >
                  <span className="btn-title">
                    <i className="far fa-calendar-check mr-2"></i>
                    {selectedLanguage == "English" ? "Book Now" : "احجز الان"}
                  </span>
                </Link> */}
                <a
                  href="tel:80 031 212"
                  data-number="80 031 212"
                  className="d-none theme-btn btn-style-one blue takeMeToBooking d-inline-blocktext-center text-uppercase callNowBtn"
                >
                  <span className="btn-title">
                    <i className="fas fa-phone rotated mr-2"></i> Call Us Today
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className="row">
      <div className="col-12">
        <div className="country-tabs">
          <ul className="tab-list">
            {Object.keys(brandData).map(country => (
              <li
                key={country}
                className={`tab-item ${selectedCountry === country ? 'active' : ''}`}
                onClick={() => setSelectedCountry(country)}
              >
                {country.toUpperCase()}
              </li>
            ))}
          </ul>
          <div className="tab-content">
            {brands && brands.map((brand, index) => (
              <div className="et-make et-icon-box" key={index}>
                <a className="d-block">
                  <div className="each_brand_category">
                    <div className="icon_container">
                      <img
                        className="img-fluid d-block mx-auto"
                        loading="lazy" 
                        alt={brand.name}
                        src={`your-image-path/${brand.image}`}
                      />
                    </div>
                    <div className="text_container">
                      <h3>{brand.name}</h3>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div> */
}
