import React, { useEffect, useState, useContext } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch } from "react-redux";
import Loader from "../components/loader/Loader";
import { ASSETS} from "../config/Constants";
import { getGallery } from "../redux/features/cms/gallerySlice";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

function Galleries() {
  const [images, setImages] = useState([]);
  const [seo, setSeo] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(getGallery()).then((data) => {
      setImages(data.payload.data.galleries);
      setSeo(data.payload.data.seo);
      setLoading(false); 
    });
  }, []);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => setLightboxOpen(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <section>
      <Helmet>
        <title>{seo &&seo.meta_title}</title>
        <meta name="title" content={seo &&seo.meta_title}/>
        <link rel="canonical" href={seo &&seo.canonical_url} />
        <meta name="description" content={seo && seo.meta_description} />
        <meta name="keywords" content={seo && seo.meta_keywords} />
      </Helmet>

       <section>
        <div className="breadCrumbWrapper">
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-left">
                    <h3 className="page_title">{selectedLanguage == "English" ?'Our Gallery':'معرضنا'}</h3>
                    <p>
                      <span>{selectedLanguage == "English" ?'Booking':'الحجز'}</span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>{selectedLanguage == "English" ?'Our Gallery':'معرضنا'}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid gallery_design popup-gallery">
        <div className="row mb-3">
          {images.map(({ image, title_ar, title_en, image_alt_text}, index) => (
            <div key={index} className="col-lg-4 order-lg-1">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="d-block" onClick={() => openLightbox(index)}>
                    <div className="image_wrapper big_image">
                      <img
                    
                        src={`${ASSETS}public/images/gallery/large/${image}`}
                        alt={image_alt_text}
                        loading="lazy" 
                        className="mb-2 img-fluid"
                            title="gallery"
                      />
                      <div className="info_wrapper">
                        <span>{selectedLanguage=='English'?title_en:title_ar}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {lightboxOpen && (
          <Lightbox
            mainSrc={`${ASSETS}public/images/gallery/large/${images[currentImageIndex].image}`}
            nextSrc={`${ASSETS}public/images/gallery/large/${images[(currentImageIndex + 1) % images.length].image}`}
            prevSrc={`${ASSETS}public/images/gallery/large/${images[(currentImageIndex + images.length - 1) % images.length].image}`}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setCurrentImageIndex(
                (currentImageIndex + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setCurrentImageIndex((currentImageIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </section>
  );
}

export default Galleries;
