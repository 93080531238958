import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "leads";





// Get landing page banners
export const getLandingBanners = createAsyncThunk(`${namespace}/LandingBanners`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}/LandingBanners`);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getLandingBannerSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getLandingBanners.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getLandingBanners.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getLandingBanners.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});