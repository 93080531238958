import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig, API_KEY } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace  = "getServicePackagesPageDataBySlug/packages";
const formSubmit = "saveTheQuoteDetails";

// Get banners
export const getPackage = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getPackageSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getPackage.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getPackage.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getPackage.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});


// ======================================================== //
// submit form


export const submitPackageQuery = createAsyncThunk(
  `${formSubmit}/`,
  async (formdata) => {
    try {
      const { data } = await axios.post(API_URL+'/'+formSubmit, formdata,{
        headers: {
          "Content-Type": "multipart/form-data",
          "token":API_KEY
        },
      });
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const submitPackageQuerySlice = createSlice({
    name: formSubmit,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [submitPackageQuery.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [submitPackageQuery.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload;
      },
      [submitPackageQuery.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });

