import React, { useEffect, useState, useContext } from "react";
import Loader from "../components/loader/Loader";
import { API_URL, axiosConfig } from "../config/Constants";
import axios from "axios";
import parse from "html-react-parser";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

export default function Privacy_policy() {
  const [content, setContent] = useState();
  const [privacy, setPrivacy] = useState();
  useEffect(() => {
    axios.get(API_URL + "/getPrivacyPolicyData/privacy-policy", axiosConfig).then((response) => {
      setContent(response.data.data.terms_and_conditions.content);
      setPrivacy(response.data.data);
    });
  }, []);
  const { selectedLanguage } = useContext(LanguageContext);

  if (!content) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{privacy.seo && privacy.seo.meta_title}</title>
        <meta name="title" content={privacy.seo && privacy.seo.meta_title} />
        <link rel="canonical" href={privacy.seo &&privacy.seo.canonical_url} />
        <meta name="description" content={privacy.seo && privacy.seo.meta_description} />
        <meta name="keywords" content={privacy.seo && privacy.seo.meta_keywords} />
      </Helmet>

      {/* Pricing Section two */}
      <section className="pricing-section-two">
        <div className="auto-container">
          <div className="sec-title text-center mb-0">
            <h2>
              {selectedLanguage == "English"
                ? content.category_title_en
                : content.category_title_ar}
            </h2>
            <span className="devider">
              <span></span>
            </span>
          </div>
        </div>
      </section>
      <section>
        <div className="slider_section_description mb-5">
          <div className="auto-container">
            <div className="content_container_about_us">
              <div className="main_content">
                {selectedLanguage === "English"
                  ? parse(content.description_en)
                  : content.description_ar
                  ? parse(content.description_ar)
                  : "No Arabic description available"}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Certificate Section */}
    </>
  );
}
