import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";
const namespace = "getGalleryPageDataBySlug/gallery";


// Get banners
export const getGallery = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getGallerySlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getGallery.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getGallery.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getGallery.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

