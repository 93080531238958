import React from "react";
import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { submitContact } from "../redux/features/customer-requests/contactusSlice";
import { useRef } from "react";
import { useEffect } from "react";
import { getContact } from "../redux/features/cms/contactSlice";
import copy from "copy-to-clipboard";
import OwlCarousel from "react-owl-carousel2";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Loader from "../components/loader/Loader";
import parse from "html-react-parser";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

export default function Contact_us() {
  const [contacts_data, setContacts_data] = useState([]);
  const [contacts_data_seo, setContacts_data_seo] = useState([]);

  const dispatch = useDispatch();
  const { selectedLanguage } = useContext(LanguageContext);
  useEffect(() => {
    dispatch(getContact()).then((data) => {
      setContacts_data(data.payload.data.contact_us);
      setContacts_data_seo(data.payload.data.seo)
    });
  }, []);

  const empty_values = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const recaptchaRef = useRef(null);

  const onsubmitContact = async (e) => {
    setIsLoading(true);
    dispatch(submitContact(formData)).then((res) => {
      if (res.payload.success) {
        toast.success(res.payload.message);
        setFormData(empty_values);
      }
      setIsLoading(false);
    });
  };



  const owlOptions = {
    items: 4,
    nav: false,
    dots: false,
    loop: true,
    margin: 20,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const [copied, setCopied] = useState(false);

  const handleCopyClick = (email) => {
    copy(email);
    setCopied(true);
  };

  if (!contacts_data || !contacts_data.section_title) {
    return <Loader />;
  }


  return (
    <>
      {/* Contact Page Section */}
      <Helmet>
        <title>{contacts_data_seo &&contacts_data_seo.meta_title}</title>
        <link rel="canonical" href={contacts_data_seo &&contacts_data_seo.canonical_url} />
        <meta name="description" content={contacts_data_seo && contacts_data_seo.meta_description} />
        <meta name="keywords" content={contacts_data_seo && contacts_data_seo.meta_keywords} />
      </Helmet>

      <section className="contact-section-two pt-4 pb-0">
        <div className="auto-container">
          <div className="inner-container">
            <div className="sec-title text-center mb-3">
              <h2>
                {selectedLanguage == "English"
                  ? contacts_data.section_title.category_title_en
                  : contacts_data.section_title.category_title_ar}
              </h2>
              <span className="devider">
                <span></span>
              </span>
              <div className="text contact-paragraph">
                {parse(
                  selectedLanguage == "English"
                    ? contacts_data.section_title.highlight_en || ""
                    : contacts_data.section_title.highlight_ar || ""
                )}
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 d-block mx-auto max1200 mb-5">
                {/* <div className="address_container text-center mb-0 mb-lg-5">
                {contacts_data.locations.length === 1 ? (
                    <div className="each_location_item move_to" moveto={"#location_0"}>
                      <span className="icon fa fa-map-marker-alt"></span>
                      <p>
                        {selectedLanguage == "English"
                          ? contacts_data.locations[0].address_en
                          : contacts_data.locations[0].address_ar}
                      </p>
                      <p>
                        <strong>
                          {selectedLanguage == "English"
                            ? contacts_data.locations[0].title_en
                            : contacts_data.locations[0].title_ar}
                        </strong>
                      </p>
                      <p>{contacts_data.locations[0].contact_number}</p>
                    </div>
                  ) : (
                    <OwlCarousel options={owlOptions}>
                      {contacts_data.locations.map((location, locationIndex) => (
                        <div className="item" key={locationIndex}>
                          <div
                            className="each_location_item move_to"
                            moveto={"#location_" + locationIndex}
                          >
                            <span className="icon fa fa-map-marker-alt"></span>
                            <p>
                              {selectedLanguage == "English"
                                ? location.address_en
                                : location.address_ar}
                            </p>
                            <p>
                              <strong>
                                {selectedLanguage == "English"
                                  ? location.title_en
                                  : location.title_ar}
                              </strong>
                            </p>
                            <p>{location.contact_number}</p>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  )}

                </div> */}
                {contacts_data.locations.map((location, locationIndex) => (
                  <div
                    className="location_wrapper_outer mt-2"
                    id={"location_" + locationIndex}
                    key={locationIndex}
                  >
                    <div className="location_wrapper">
                      <h3>
                        {selectedLanguage == "English"
                          ? location.title_en
                          : location.title_ar}
                      </h3>
                    </div>
                    <div className="contact_wrapper">
                      <div className="row">
                        <div className="col-lg-6 order-lg-1 ">
                          <div className="mapouter">
                            <div className="gmap_canvas" id="locMapDetail2">
                              <iframe
                                src={location.location_map_url}
                                width="100%"
                                height="250"
                                frameBorder="0"
                                allowFullScreen=""
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 d-block m-auto contactUsInformation">
                          <div className="p-3 p-md-3">
                            <div className="icons-wrapper-contact-page-wrapper">
                              <div className="row no-gutters">
                                <div className="col-sm-2 col-12 text-sm-right pr-3">
                                  <a href="#">
                                    <span className="icon-wrapper-contact-us hovicon effect-7 sub-b">
                                      <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                  </a>
                                </div>
                                <div className="col-sm-10 col-12">
                                  <a
                                    className="bold-text primary-color"
                                    href="#"
                                  >
                                    {selectedLanguage == "English"
                                      ? location.title_en
                                      : location.title_ar}
                                  </a>
                                  <p>
                                    {selectedLanguage == "English"
                                      ? location.address_en
                                      : location.address_ar}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="icons-wrapper-contact-page-wrapper"
                              data-aos-delay="300"
                            >
                              <div className="row no-gutters">
                                <div className="col-sm-2 col-12 text-sm-right pr-3">
                                  <a href=" tel:+97143397533">
                                    <span className="icon-wrapper-contact-us hovicon effect-7 sub-b">
                                      <i className="fas fa-phone"></i>
                                    </span>
                                  </a>
                                </div>
                                <div className="col-sm-10 col-12" id="contactus_phone_number">
                                  <a
                                    className="bold-text mt-4"
                                    href="tel:800 2886349"
                                  >
                                    {location.contact_number}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              className="icons-wrapper-contact-page-wrapper"
                              data-aos-delay="300"
                            >
                              <div className="row no-gutters mt-4">
                                <div className="col-sm-2 col-12 text-sm-right pr-3">
                                  <a href=" tel:+97143397533">
                                    <span className="icon-wrapper-contact-us hovicon effect-7 sub-b">
                                      <i className="fas fa-calendar-week"></i>
                                    </span>
                                  </a>
                                </div>
                                <div className="col-sm-10 col-12">
                                  <a className="bold-text" href="#">
                                    {selectedLanguage == "English"
                                      ? location.opening_days_en
                                      : location.opening_days_ar}
                                  </a>
                                  <p>
                                  <a className="bold-text" href="#">
                                    {selectedLanguage == "English"
                                      ? parse(location.opening_hours_en)
                                      : parse(location.opening_hours_ar)}
                                  </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="icons-wrapper-contact-page-wrapper"
                              data-aos-delay="600"
                            >
                              <div className="row no-gutters">
                                <div className="col-sm-2 col-12 text-sm-right pr-3">
                                  <a href="mailto:er.info@arcs-me.com">
                                    <span className="icon-wrapper-contact-us hovicon effect-7 sub-b">
                                      <i className="far fa-envelope"></i>
                                    </span>
                                  </a>
                                </div>
                                <div className="col-sm-10 col-12">
                                  <a
                                    className="bold-text"
                                    href={"mailto:" + location.email}
                                  >
                                    {location.email}
                                  </a>
                                  {/* <p className="m-0">
                                    <span
                                      className="copyEmail"
                                      onClick={() =>
                                        handleCopyClick(location.email)
                                      }
                                      style={inlineStyles}
                                    >
                                      {copied
                                        ? "Email address copied"
                                        : "click here to copy"}
                                    </span>
                                  </p> */}
                                  <p>
                                    Email us anytime, we usually reply within 24
                                    hours
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <section className="contact-section-two pt-4 pb-0">
                  <div className="auto-container pt-4">
                    <div className="inner-container">
                      <div className="sec-title text-center mb-3">
                        <h2>Get in Touch</h2>

                        <div className="text">
                          Your Dedicated Personal Car Service Advisor is
                          Available to Assist You.
                          <br />
                        </div>
                      </div>

                      <div className="contact-form-two mt-4 px-3 pt-3">
                        <h3 className="common_heading mb-3">LEAVE A MESSAGE</h3>
                        <form>
                          <div className="row clearfix">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <input
                                type="text"
                                name="name"
                                id="id"
                                value={formData.name}
                                placeholder="Name"
                                onChange={onChange}
                                required=""
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group contactus">
                            <PhoneInput
                              country={'bh'} 
                              value={formData.phone}
                              onChange={(value) => setFormData({ ...formData, phone: value })} 
                              inputProps={{
                                name: 'phone',
                                id: 'phone',
                                required: true,
                                autoFocus: true,
                                className: 'inp_field phone-input phone-input-contactus'
                              }}
                              placeholder="Enter Contact Number"
                            />


                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <input
                                type="email"
                                name="email"
                                onChange={onChange}
                                value={formData.email}
                                placeholder="Email"
                                required=""
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                              <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                onChange={onChange}
                                placeholder="Subject"
                                required=""
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <textarea
                                name="message"
                                id="message"
                                value={formData.message}
                                placeholder="Message"
                                onChange={onChange}
                              ></textarea>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              {isLoading ? (
                                <div className="text-center">
                                  <img
                                    className="mx-auto"
                                    src="/assets/front/loader/loader.gif"
                                    style={{ Height: "50px" }}
                                      alt="Loader"
                            title="loading..."
                                  />
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="text-center mx-auto">
                                    {/* <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={SITE_KEY}
                                    /> */}

                                    <button
                                      className="theme-btn btn-style-two text-center mt-4 submitFormButton"
                                      type="button"
                                      onClick={() => onsubmitContact()}
                                      name="submit-form"
                                    >
                                      <span className="btn-title">
                                        Send Message
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*End Contact Page Section */}
    </>
  );
}
